import {
  ICheckListCustomField,
  ICheckListCustomFieldOption,
  ICheckListTemplateItem,
  ICheckListTemplateSection,
  ICustomFieldAttachment,
  ICustomFieldDateValue,
  ICustomFieldDatetimeValue,
  ICustomFieldFloatValue,
  ICustomFieldIntValue,
  ICustomFieldNote,
  ICustomFieldSelectValue,
  ICustomFieldStampInputFormValue,
  ICustomFieldTextValue,
} from '@/modules/checkList';
import { isSection } from '@/utils/checkListItems/checkListItems';
import { formatDateToYYYYMD, formatDateToYYYYMDHHmm } from '../date/date';

export type CheckListFormValueType = {
  customFieldNotes: ICustomFieldNote[];
  customFieldAttachments: ICustomFieldAttachment[];
  customFieldTextValues: ICustomFieldTextValue[];
  customFieldDateValues: ICustomFieldDateValue[];
  customFieldIntValues: ICustomFieldIntValue[];
  customFieldFloatValues: ICustomFieldFloatValue[];
  customFieldDatetimeValues: ICustomFieldDatetimeValue[];
  customFieldSelectValues: ICustomFieldSelectValue[];
  customFieldStampValues: ICustomFieldStampInputFormValue[];
};

export const getReferenceCustomFieldOptions = (
  data: { template: { items: ICheckListTemplateItem[] } }[]
): ICheckListCustomFieldOption[] => {
  return data.flatMap((checkList) => {
    return checkList.template.items.flatMap((item) => {
      if (isSection(item)) {
        return (item as ICheckListTemplateSection).items.flatMap((item) => {
          return item.options ?? [];
        });
      } else {
        return 'options' in item ? (item.options ?? []) : [];
      }
    });
  });
};

export const getValueByType = (
  customField: ICheckListCustomField,
  checkListFormValue: CheckListFormValueType,
  referenceCustomFieldIds: number[] = [],
  referenceCustomFieldOptions: ICheckListCustomFieldOption[] = []
): string | number => {
  let defaultValue: number | string = '';

  switch (customField.type) {
    case 'text':
      const customFieldTextValue = checkListFormValue.customFieldTextValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );
      defaultValue = (customFieldTextValue && customFieldTextValue.value) || '';
      break;
    case 'datetime':
      const customFieldDatetimeValue = checkListFormValue.customFieldDatetimeValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );

      defaultValue = customFieldDatetimeValue
        ? formatDateToYYYYMDHHmm(customFieldDatetimeValue.value)
        : '';
      break;
    case 'date':
      const customFieldDateValue = checkListFormValue.customFieldDateValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );
      defaultValue = (customFieldDateValue && formatDateToYYYYMD(customFieldDateValue.value)) || '';
      break;

    case 'select':
      const customFieldSelectValue = checkListFormValue.customFieldSelectValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );
      if (customFieldSelectValue) {
        const option =
          customField.options.find((option) => option.id === customFieldSelectValue.optionId) ||
          referenceCustomFieldOptions.find(
            (option) => option.id === customFieldSelectValue.optionId
          );
        if (option === undefined) {
          throw new Error('option is undefined');
        }
        defaultValue = option.value;
      }
      break;
    case 'float':
      const customFieldFloatValue = checkListFormValue.customFieldFloatValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );

      defaultValue = (customFieldFloatValue && customFieldFloatValue.value) ?? '';
      break;

    case 'int':
      const customFieldIntValue = checkListFormValue.customFieldIntValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );
      defaultValue = (customFieldIntValue && customFieldIntValue.value) ?? '';
      break;

    case 'stamp':
      const customFieldStampValue = checkListFormValue.customFieldStampValues.find(
        (value) =>
          value.customFieldId === customField.id ||
          referenceCustomFieldIds.includes(value.customFieldId)
      );
      if (customFieldStampValue) {
        defaultValue = `${customFieldStampValue.stampedBy.name}(${formatDateToYYYYMD(
          customFieldStampValue.stampedAt
        )})`;
      }
      break;
  }

  return defaultValue;
};

export const createCheckListFormValue = (
  checkList: CheckListFormValueType
): CheckListFormValueType => {
  const checkListFormValue = {
    customFieldNotes: checkList.customFieldNotes.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        note: entry.note,
      };
    }),
    customFieldAttachments: checkList.customFieldAttachments.map((entry) => {
      return {
        ...entry,
      };
    }),
    customFieldTextValues: checkList.customFieldTextValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        value: entry.value,
      };
    }),
    customFieldDateValues: checkList.customFieldDateValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        value: entry.value,
      };
    }),
    customFieldIntValues: checkList.customFieldIntValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        value: entry.value,
      };
    }),
    customFieldFloatValues: checkList.customFieldFloatValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        value: entry.value,
      };
    }),
    customFieldDatetimeValues: checkList.customFieldDatetimeValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        value: entry.value,
      };
    }),
    customFieldSelectValues: checkList.customFieldSelectValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        optionId: entry.optionId,
      };
    }),
    customFieldStampValues: checkList.customFieldStampValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        stampedAt: entry.stampedAt,
        stampedBy: entry.stampedBy,
      };
    }),
  };
  return checkListFormValue;
};

export const generateCheckListInitFormValue = (): CheckListFormValueType => {
  return {
    customFieldNotes: [],
    customFieldAttachments: [],
    customFieldTextValues: [],
    customFieldDateValues: [],
    customFieldIntValues: [],
    customFieldFloatValues: [],
    customFieldDatetimeValues: [],
    customFieldSelectValues: [],
    customFieldStampValues: [],
  };
};
